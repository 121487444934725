import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      apikey: 'bK4UakVAn74TUe1Wm9Uri9NX8zO3VgHF',
    }),
  };

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient,
    private authservice: AuthenticationService
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token, cookie) {
    // we can change this function to request our backend service
    // this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
    //   const data = {};
    //   data[userId] = token;
    //   console.log(userId);
    //   console.log(token);
    //   console.log(data);
    //   this.angularFireDB.object('fcmTokens/').update(data);

    //   // Update the token here for a sender
    // });
    this.updateOrStoreToken({
      employeeIdentifier: userId,
      token: token,
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId, cookie) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.updateToken(userId, token, cookie);
        this.authservice.loadQCWidget(
          document,
          'script',
          this.authservice.currentUserValue,
          token
        );
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
        this.authservice.loadQCWidget(
          document,
          'script',
          this.authservice.currentUserValue,
          ''
        );
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log('new message received. ', payload);
      this.currentMessage.next(payload);
    });
  }

  /**
  Update / Store the token of the senderId
  */

  updateOrStoreToken(payload) {
    return this.http
      .post(
        'https://apim.quickwork.co/axisbankltd/productivitypwa/v1/upserttoken',
        payload,
        this.httpOptions
      )
      .pipe()
      .subscribe((response) => {
        console.log(response);
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { first } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  buttonText: string = 'Generate OTP';
  isOTPGenerated: boolean = false;
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  message = '';

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    console.log(
      'LOADED LOGIN COMPONENT ',
      this.authenticationService.currentUserValue
    );
    if (this.authenticationService.currentUserValue != null) {
      this.router.navigate(['dashboard']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      employeeIdentifier: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^([_a-z0-9]+(\\.[_a-z0-9]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,5}))|(\\d+$)$'
          ),
        ],
      ],
      otp: ['', Validators.required],
    });
  }

  generateOtp() {
    console.log();
    this.submitted = true;

    if (this.loginForm.controls.employeeIdentifier.errors) {
      return;
    }
    this.loading = true;

    this.authenticationService
      .generateOTP(this.f.employeeIdentifier.value.toLowerCase())
      .pipe()
      .subscribe(
        (data) => {
          console.log('data', data);
          if (data.isOTPGenerated) {
            this.buttonText = 'Validate OTP';
            this.isOTPGenerated = true;
            this.loading = false;

            this.toastr.success(
              `<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">${data.message}</span>`,
              '',
              {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: 'alert alert-success alert-with-icon',
                positionClass: 'toast-top-center',
              }
            );
          } else {
            this.loading = false;
            this.toastr.warning(
              `<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">${data.message}</span>`,
              '',
              {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: 'alert alert-danger alert-with-icon',
                positionClass: 'toast-top-center',
              }
            );
          }
          this.submitted = false;
        },
        (error) => {
          this.loading = false;
        }
      );
    //Make an API call to generate OTP.
  }
  get f() {
    return this.loginForm.controls;
  }

  validateOTP() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService
      .validateOTP(this.f.employeeIdentifier.value, this.f.otp.value)
      .pipe()
      .subscribe(
        (data) => {
          console.log(data);
          if (data.isValid) {
            this.loading = false;

            this.router.navigate(['/dashboard']);
          } else {
            this.loading = false;
            this.toastr.warning(
              `<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">${data.message}</span>`,
              '',
              {
                timeOut: 4000,
                closeButton: true,
                enableHtml: true,
                toastClass: 'alert alert-danger alert-with-icon',
                positionClass: 'toast-top-center',
              }
            );
          }
        },
        (error) => {
          console.log(error);
          //this.error = error;
          //this.loading = false;
        }
      );
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, config, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "bK4UakVAn74TUe1Wm9Uri9NX8zO3VgHF",
    }),
  };
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    console.log(this.currentUser);
  }

  public get currentUserValue() {
    console.log(
      "this.currentUserSubject.value ",
      this.currentUserSubject.value
    );
    return this.currentUserSubject.value;
  }

  validateOTP(employeeIdentifier, otp) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/axisbankltd/productivitypwa/v1/validateotp",
        { employeeIdentifier, otp },
        this.httpOptions
      )
      .pipe(
        map((response) => {
          if (response.isValid) {
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response.employeeDetails)
            );
            console.log(response.employeeDetails);
            this.currentUserSubject.next(response.employeeDetails);
          }
          console.log("printed");
          return response;
        })
      );
  }
  generateOTP(employeeIdentifier) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/axisbankltd/productivitypwa/v1/generateotp",
        { employeeIdentifier },
        this.httpOptions
      )
      .pipe(
        map((response) => {
          // localStorage.setItem("currentUser", JSON.stringify(user));
          // this.currentUserSubject.next(user);
          // return user;
          return response;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
  }

  initializeChatWithToken(employeeId) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/mediclaim/fetchcookie_uat",
        { employeeId },
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  insertChatCookie(employeeId, cookie) {
    return this.http
      .post<any>(
        "https://apim.quickwork.co/ayyub/svatantra/v1/mediclaim/insertcookie_uat",
        { employeeId, cookie },
        this.httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  g: HTMLImageElement;
  s = document.createElement("script");
  loadQCWidget(d, t, userDetails, token) {
    var BASE_URL = "https://chat.quickwork.co";
    var WEBSITE_TOKEN = "fypVtJMrE4YzrSWiT923485s";
    (this.g = d.createElement(t)), (this.s = d.getElementsByTagName(t)[0]);
    this.g.src = BASE_URL + "/packs/js/sdk.js";
    this.s.parentNode.insertBefore(this.g, this.s);
    this.g.onload = function () {
      window["qwcSDK"].run({
        websiteToken: WEBSITE_TOKEN,
        baseUrl: BASE_URL,
        settings: {
          custom_payload: { userDetails: userDetails, fcmToken: token },
        },
      });
      window["qwcSDK"].setToggleWidget();
      window["qwcSDK"].hideTitleCloseButton();
      console.log(window["qwcSDK"]);
    };
  }
}

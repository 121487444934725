import { Component, ElementRef, OnInit } from '@angular/core';
import { MessagingService } from '../shared/messaging.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  message;
  cookieStr;
  constructor(
    private messagingService: MessagingService,
    private cookies: CookieService,
    private authservice: AuthenticationService,
    private elementRef: ElementRef,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.authservice.currentUserValue != null) {
      this.elementRef.nativeElement.appendChild(this.s);
      const userId = this.authservice.currentUserValue['id'];
      this.messagingService.requestPermission(userId, '');
      this.messagingService.receiveMessage();
      this.message = this.messagingService.currentMessage;
    } else {
      this.router.navigate['login'];
    }
  }

  g: HTMLImageElement;
  s = document.createElement('script');
}

import { Component } from "@angular/core";

import { MessagingService } from "./shared/messaging.service";
import { CookieService } from "ngx-cookie-service";
import { Router } from "@angular/router";
import { AuthenticationService } from "./authentication.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [],
})
export class AppComponent {
  title = "axisbank-productivity-pwa";
  message;
  currentUser: any;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    console.log(
      "APP COMPONENT LOADED ",
      this.authenticationService.currentUserValue
    );
    if (this.authenticationService.currentUserValue != null) {
      console.log("Redirecting to dashboard");
      this.router.navigate(["dashboard"]);
    } else {
      console.log("Redirecting to login");
      this.router.navigate(["login"]);
    }
  }
}

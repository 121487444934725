<div class="LoginContainer" style="margin: auto; width: 80%">
  <div class="row">
    <div class="col-md-12">
      <div class="logo" style="margin: auto; width: 150px">
        <div class="logo-image-small">
          <!-- <img src="https://www.svatantramicrofin.com/images/SVlogo.png" /> -->
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card card-nav-tabs text-center">
        <div class="card-header">
          <h5 class="card-title">Welcome to Axis Bank Mortgage Team Productivity Bot</h5>
          <p class="card-category">Please login to proceed</p>
        </div>
        <div class="card-body" align="center">
          <form [formGroup]="loginForm">
            <div class="">
              <div class="col-md-6 pr-1">
                <div class="form-group">
                  <label>Employee Phone Number</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Please enter your phone number"
                    [ngClass]="{
                      'is-invalid': submitted && f.employeeIdentifier.errors
                    }"
                    formControlName="employeeIdentifier"
                  />
                </div>
              </div>
            </div>
            <div *ngIf="isOTPGenerated == true">
              <div class="col-md-6 pr-1">
                <div class="form-group">
                  <label>Enter OTP</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    [ngClass]="{
                      'is-invalid': submitted && f.otp.errors
                    }"
                    formControlName="otp"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="update ml-auto mr-auto">
                <button
                  *ngIf="isOTPGenerated == false"
                  (click)="generateOtp()"
                  class="btn btn-info btn-round"
                  style="margin-right: 10px"
                >
                  <span
                    *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"
                  ></span>
                  <i
                    *ngIf="loading == false"
                    class="fa fa-mobile"
                    style="padding-right: 4px"
                  ></i
                  >{{ buttonText }}
                </button>
                <button
                  *ngIf="isOTPGenerated == true"
                  (click)="validateOTP()"
                  class="btn btn-info btn-round"
                  style="margin-right: 10px"
                >
                  <span
                    *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"
                  ></span>
                  <i
                    *ngIf="loading == false"
                    class="fa fa-mobile"
                    style="padding-right: 4px"
                  ></i
                  >{{ buttonText }}
                </button>
             
              </div>
            </div>
          </form>
        </div>
  
      </div>
    </div>
  </div>
</div>

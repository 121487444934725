export const environment = {
  production: false,
  // firebase: {
  //   apiKey: 'AIzaSyAC4Vrp7mhSlAAsXQyFvYfxdsq1rA99AK4',
  //   authDomain: 'svatantra-mediclaim-pwa.firebaseapp.com',
  //   databaseURL: 'https://svatantra-mediclaim-pwa-default-rtdb.firebaseio.com/',
  //   projectId: 'svatantra-mediclaim-pwa',
  //   storageBucket: 'svatantra-mediclaim-pwa.appspot.com',
  //   messagingSenderId: '318852552503',
  //   appId: '1:318852552503:web:96bfcf422f2d48244fecb2',
  // },
  firebase: {
    apiKey: "AIzaSyAOFMKzrIfWR6Or2GvTQVWfBF6SQJ0VP84",
    authDomain: "axisbank-productivity-bot-pwa.firebaseapp.com",
    projectId: "axisbank-productivity-bot-pwa",
    storageBucket: "axisbank-productivity-bot-pwa.appspot.com",
    messagingSenderId: "960807380174",
    appId: "1:960807380174:web:be009df4adc4e91ce89c9f"
  }
};
